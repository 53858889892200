<template>
  <div>
    <q-skeleton height="50px" style="margin-top: 10px" />
    <q-skeleton height="80px" style="margin-top: 10px" />
  </div>
</template>

<script>
export default {
  name: "HcmGroupOpeningTeam",
  created() {
    this.$proSmart.documentUi
      .createAndCheckHcmGroupForOpening(this, this.$route.params.id)
      .then((tenderUserRole) => {
        this.$router.replace({
          name: "DocumentDashboard",
          params: {
            id: this.$route.params.id,
            userRole: tenderUserRole.keyName,
            category: this.$route.params.category,
            requestType: this.$route.params.requestType,
          },
        });
      })
      .catch(() => this.$router.go(-1));
  },
};
</script>
